import config from '../../config/config';

config.oauth_client = '9397a8e8-0afe-4c77-9fdc-c9f9be8c854a';
config.oauth_callback = 'http://localhost:8094/oauth';
config.base_uri = 'http://localhost:8094/';
config.push_public_key = 'BM0-zOyfqjgITKWq9WQEsH-SBm6iDgA1vTp865zztIyr1kPELmwZ23wRiaC6XDToIHmvgZJIzLkAxwhe5RJjAaE';
config.ph_beheer_url = 'http://localhost:3001';

if (process.env.REACT_APP_TOEG_ENV === 'test') {
    config.base_uri = 'https://beheer-test.toegang.org/';
    config.oauth_callback = config.base_uri + 'oauth';
    config.push_public_key = 'BE59O9s1iSI5dbmH9oW6qycujwWounbWzSKFmXZGqx4ukBT5w8NCPhAcQCm_o1tMZFMfW0DZhkMfy8RrwNatIKc';
    config.ph_beheer_url = 'https://acc.profiel.thiememeulenhoff.nl';
}
if (process.env.REACT_APP_TOEG_ENV === 'ontwikkel') {
    config.base_uri = 'https://beheer-ontwikkel.toegang.org/';
    config.oauth_callback = config.base_uri + 'oauth';
    config.push_public_key = 'BJDullrk5cN7_UsPC5Raz6jD1oX435e0sQux3HT8uRXL8C_WwPg1lsgKZSOna0AiX-6g0S5INPdKSPD4lEpnBR0';
    config.ph_beheer_url = 'https://profiel.ontwikkel.toegang.org';
}
if (process.env.REACT_APP_TOEG_ENV === 'buildstraat') {
    config.base_uri = `https://${config.branch}-dot-beheer-dot-toegang-ontwikkel.appspot.com/`;
    config.oauth_callback = config.base_uri + 'oauth';
    config.push_public_key = 'BJDullrk5cN7_UsPC5Raz6jD1oX435e0sQux3HT8uRXL8C_WwPg1lsgKZSOna0AiX-6g0S5INPdKSPD4lEpnBR0';
}
if (process.env.REACT_APP_TOEG_ENV === 'productie') {
    config.base_uri = 'https://beheer.toegang.org/';
    config.oauth_callback = config.base_uri + 'oauth';
    config.push_public_key = 'BI4hdqJdmbs3cUigQ0qKDJI9cREvQVw3Lj58KQaOIHzvmap8VtgrIjdbK3gt7-flDBvISaKco6bQS2DH5J_x91o';
    config.ph_beheer_url = 'https://profiel.thiememeulenhoff.nl';
    // config.ph_beheer_url = 'https://profiel.toegang.org'; // todo Mag weg na de release
}

export default config;
